/* eslint-disable react-hooks/exhaustive-deps */
import { HStack, Text, VStack, Box } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackArrowIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';
import { getDateInterval } from '../../pages/dashboard/getDateInterval';
import PropTypes from 'prop-types';
import CustomDateFilter from './DashboardFilter';
import { toTitleCase } from '../../utils/common.utils';
import { useBoolean } from '@laxmimanogna/code-quick-components';
import AppColors from '../../constants/AppColors';

function DashBoardHeader({
  isFetchingHospital,
  titleName,
  setFromDate,
  navigationRoute,
  fromDate,
  setFilter,
  filter,
  objectWithDate,
  logo = null,
}) {
  const navigate = useNavigate();
  const selectedFilter = JSON.parse(
    window.localStorage.getItem('selectedFilter')
  );
  const selectedCustomDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );
  const [isOpenDateFilter, iodfState] = useBoolean(false);

  useEffect(() => {
    if (selectedFilter) {
      setFilter(selectedFilter.toLowerCase());
    }
    if (selectedFilter === 'custom') {
      setFromDate({
        previous_start_date: new Date(selectedCustomDates?.previous_start_date),
        previous_end_date: new Date(selectedCustomDates?.previous_end_date),
        start_date: new Date(selectedCustomDates?.start_date),
        end_date: new Date(selectedCustomDates?.end_date),
      });
    } else {
      const selectedDate = getDateInterval(
        toTitleCase(selectedFilter?.toLowerCase())
      );
      setFromDate({ ...selectedDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HStack width={'100%'} justifyContent={'space-between'}>
        <HStack>
          <BackArrowIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate(navigationRoute);
            }}
          />
          {logo && (
            <Box
              bg="white"
              borderRadius="full"
              boxShadow="0px 0px 8px rgba(67, 218, 212, 0.4)"
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={logo}
                alt="Client Logo"
                style={{
                  width: '70px',
                  height: '70px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />
            </Box>
          )}
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontWeight={600}
            fontSize={'20'}
            paddingLeft={3}
          >
            {isFetchingHospital ? 'Loading' : titleName}
          </Text>
        </HStack>

        <CustomDateFilter
          filterParams={{ ...fromDate, label: filter }}
          onDateSubmit={data => {
            setFromDate(data);
            setFilter('custom');
          }}
          openDateFilter={() => iodfState.on()}
          isOpenDateFilter={isOpenDateFilter}
          onDateClose={() => iodfState.off()}
          isLoading={false}
          objectWithDate={objectWithDate}
          onSelectClick={type => {
            if (type.value !== 'custom') {
              const data = getDateInterval(type.value);
              setFromDate({ ...data });
              setFilter(type.value);
            }
          }}
        />
      </HStack>
    </>
  );
}

DashBoardHeader.propTypes = {
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  cq_score: PropTypes.object,
  isFetchingHospital: PropTypes.bool,
  titleName: PropTypes.string,
  setFromDate: PropTypes.func,
  navigationRoute: PropTypes.string,
  setFilter: PropTypes.func,
  fromDate: PropTypes.object,
};

export default DashBoardHeader;
