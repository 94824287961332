import { useBoolean } from '@chakra-ui/react';
import { useToastr } from '@laxmimanogna/code-quick-components';
import { createContext, useState } from 'react';
import myAccountRepository from '../repositories/MyAccountRepository';
import { successToast } from '../components/successToast';
import { getDictionaryKeyForClientTypeData } from '../utils/clientTypeUtils'

export const MyAccountContext = createContext({
  currentUserType: null,
  isFetchingDepartment: false,
  userTypeLookup: async () => {},
  editUserData: async () => {},
  editUserLogo: async () => {},
  isGettingUserType: false,
  currentUserPracticeInfo: null,
  practiceInfoHealthSystem: async () => {},
  practiceInfoProvider: async () => {},
  praticeInfoHospital: async () => {},
  praticeInfoPhysicianGroup: async () => {},
  praticeInfoDepartment: async () => {},
  createHospital: async () => {},
  getProvider: async () => {},
  getDepartmentDropdown: async () => {},
  createDepartment: async () => {},
  getDepartmentByKeyword: async () => {},
  updateHealthSystemPracticeInfo: async () => {},
  updateHospitalPracticeInfo: async () => {},
  updateDepartmentPracticeInfo: async () => {},
  uploadingLogoStatus: {},
  deleteHospital: async () => {},
  deleteDepartment: async () => {},
//   updateDepartmentPracticeInfo: async () => {},
  getDepartmentByHospital: async () => {},
  isDepartmentLoading:false,
  isUpdatingUserData:false,
  setSpecialtyOptions: async () => {},

  departmentDropdown: [],
  setDepartmentDropdown: () => {},

  // Insurance Related
  insuranceOptions: [],
  isFetchingInsurance: false,
  isCreatingInsurance: false,
  fetchInsuranceOptions: async () => null,
  createInsuranceOption: async insuranceName => null,

  // EHR Related
  ehrOptions: [],
  isFetchingEHR: false,
  isCreatingEHR: false,
  fetchEHROptions: async () => {},
  createEHROptions: async optionName => {},

  // specialty Related
  specialtyOptions: [],
  isFetchingSpecialtyOption: false,
  isCreatingSpecialtyOption: false,
  fetchSpecialtyOptions: async () => null,
  createSpecialtyOption: async specialtyName => null,
});

const MyAccountProvider = props => {
  const toast = useToastr();

  function showErrorMessage(errors) {
    for (const key in errors) {
      const error = errors[key];
      if (typeof error === 'string') {
        toast.showError({ description: error });
      } else if (typeof error === 'object') {
        showErrorMessage(error);
      }
    }
  }

  const [isGettingUserType, igutState] = useBoolean(false);
  const [isUpdatingUserData, iuudState] = useBoolean(false);
  const [currentUserType, setCurrentUserType] = useState(null);
  const [isFetchingDepartment, setIsFetchingDepartment] = useBoolean(false);

  // Specialty Options related states
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [isFetchingSpecialtyOption, ifsoState] = useBoolean(false);
  const [isCreatingSpecialtyOption, icsoState] = useBoolean(false);

  // EHR Options related states
  const [ehrOptions, setEHROptions] = useState([]);
  const [isFetchingEHR, ifeState] = useBoolean(false);
  const [isCreatingEHR, iceState] = useBoolean(false);

  // Insurance Options related states
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [isFetchingInsurance, ifiState] = useBoolean(false);
  const [isCreatingInsurance, icfState] = useBoolean(false);

  const [isDepartmentLoading, idlState] = useBoolean(false);
  const [departmentDropdown, setDepartmentDropdown] = useState([]);
  const [currentUserPracticeInfo, setCurrentUserPracticeInfo] = useState(null);

  // for maintaining user type logo upload progress
  const [uploadingLogoStatus, setUploadingLogoStatus] = useState({})


 

  async function userTypeLookup() {
    try {
      igutState.on();
      const response = await myAccountRepository.userTypeLookup();
      setCurrentUserType(response);
    } catch (e) {
      toast.showError({
        description: e.toString(),
      });
    } finally {
      igutState.off();
    }
  }

  async function editUserData(userBody) {
    try {
      iuudState.on();
      const response = await myAccountRepository.editUserData(userBody);
      const toastProps = {
        header: 'Success!',
        description: `${'Updated Successfully'}`,
      };
      successToast(toast, toastProps);

      const userNames = {
        first_name: response.first_name,
        last_name: response.last_name,
      };
      
      setCurrentUserType((prev)=>({...prev,...userNames}));
      return response;
    } catch (e) {
      const response = e.cause;
      showErrorMessage(response);
    } finally {
      iuudState.off();
    }
  }

  async function editUserTypeLogo(attribute, logo) {
    try {
      iuudState.on();
      const formData = new FormData();
        
      formData.append('attribute', attribute);
      if (logo) {
        formData.append(attribute, logo);
      }
      

      const response = await myAccountRepository.editClientTypeLogo(formData);
      if (response.status === 200) {
        const CLIENT_TYPE_KEY = getDictionaryKeyForClientTypeData(currentUserType.type);

        setCurrentUserType((prev)=>({...prev,
          [CLIENT_TYPE_KEY]: {
            ...prev[CLIENT_TYPE_KEY], 
            [attribute]: response.data[attribute]
          }}
        ));
      const toastProps = {
        header: 'Success!',
        description: `${'Updated Successfully'}`,
      };
      successToast(toast, toastProps);
    }
      return response;
    } catch (e) {
      const response = e.cause;
      showErrorMessage(response);
    } finally {
      iuudState.off();
    }

  }

  async function fetchEHROptions() {
    try {
      ifeState.on();
      const response = await myAccountRepository.getEHROptions();
      const options = response.map(res => ({ label: res.name, value: res.id }));
      setEHROptions([...options]);
    } catch (e) {
    } finally {
      ifeState.off();
    }
  }

  async function createEHROptions(optionName) {
    try {
      iceState.on();
      if (ehrOptions.findIndex(d => d.label === optionName) === -1) {
        setEHROptions(prev => [
          ...prev,
          { label: optionName, value: optionName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      iceState.off();
    }
  }

  async function fetchInsuranceOptions() {
    try {
      ifiState.on();
      const response = await myAccountRepository.getInsuranceOptions();
      const insuranceList = response?.map(opt => {
        const options = {
          label: opt.name,
          value: opt.id,
        };
        return options;
      });
      setInsuranceOptions(insuranceList);
    } catch (e) {
    } finally {
      ifiState.off();
    }
  }

//   async function fetchSpecialtyOptions() {
//     try {
//       ifsoState.on();
//       const response = await myAccountRepository.getSpecialtyOptions();
//       const speciality = response?.map(opt => {
//         const options = {
//           label: opt.name,
//           value: opt.id,
//         };
//         return options;
//       });
//       setSpecialtyOptions(speciality);
//     } catch (e) {
//     } finally {
//       ifsoState.off();
//     }
//   }

  async function fetchSpecialtyOptions(queryParams = {}) {
    try {
      ifsoState.on();
      const response = await myAccountRepository.getSpecialtyOptions(
        queryParams
      );
      setSpecialtyOptions(response);
    } catch (e) {
      // TODO: Handle Exception
    } finally {
      ifsoState.off();
    }
  }

  async function createSpecialtyOption(specialtyName) {
    try {
      icsoState.on();
      if (specialtyOptions.findIndex(d => d.label === specialtyName) === -1) {
        setSpecialtyOptions(prev => [
          ...prev,
          { label: specialtyName, value: specialtyName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      icsoState.off();
    }
  }

  async function createInsuranceOption(insuranceName) {
    try {
      icfState.on();
      if (insuranceOptions.findIndex(d => d.label === insuranceName) === -1) {
        setInsuranceOptions(prev => [
          ...prev,
          { label: insuranceName, value: insuranceName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      icfState.off();
    }
  }

  async function practiceInfoHealthSystem(health_system_id) {
    try {
      igutState.on();
      const response = await myAccountRepository.practiceInfoHealthSystem(
        health_system_id
      );
      setCurrentUserPracticeInfo(response);
      return response;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    } finally {
      igutState.off();
    }
  }

  async function praticeInfoHospital(hospital_id) {
    try {
      igutState.on();
      const response = await myAccountRepository.praticeInfoHospital(
        hospital_id
      );
      setCurrentUserPracticeInfo(response);
      return response;
    } catch (e) {
      toast.showError({
        description: e.toString,
      });
    } finally {
      igutState.off();
    }
  }

  async function praticeInfoPhysicianGroup(health_system_id) {
    try {
      igutState.on();
      const response = await myAccountRepository.praticeInfoPhysicianGroup(
        health_system_id
      );
      setCurrentUserPracticeInfo(response);
      return response;
    } catch (e) {
      toast.showError({
        description: e.toString,
      });
    } finally {
      igutState.off();
    }
  }

  async function praticeInfoDepartment(department_id) {
    try {
      igutState.on();
      const response = await myAccountRepository.praticeInfoDepartment(
        department_id
      );
      setCurrentUserPracticeInfo(response);
      return response
    } catch (e) {
      toast.showError({
        description: e.toString,
      });
    } finally {
      igutState.off();
    }
  }

  async function practiceInfoProvider(provider_id) {
    try {
      igutState.on();
      const response = await myAccountRepository.practiceInfoProvider(
        provider_id
      );
      setCurrentUserPracticeInfo(response);
      return response;
    } catch (e) {
      toast.showError({
        description: e.toString,
      });
    } finally {
      igutState.off();
    }
  }

  async function createHospital(hospitalData) {
    try {
        idlState.on();
        const res = await myAccountRepository.createHospital(hospitalData);
        const toastProps = {
          header: 'Success!',
          description: `${'Hospital Created'}`,
        };
        successToast(toast, toastProps);
        return res;
    } catch (error) {
        const response = error.cause;
        showErrorMessage(response);
    }
    finally {
        idlState.off();
      }
  }

  const getProvider = async providerId => {
    try {
      const response = await myAccountRepository.getProvider(providerId);
      return response;
    } catch (error) {
      toast.showError({ description: error.toString() });
    }
  };

  const getDepartmentDropdown = async hospitalId => {
    try {
      setIsFetchingDepartment.on();
      const res = await myAccountRepository.getDepartmentDropdown(hospitalId);
      const data = res.map(d => ({ ...d, label: d.name, value: d.id }));
      setDepartmentDropdown(data);
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      setIsFetchingDepartment.off();
    }
  };

  const createDepartment = async body => {
    const { hospitalId, ...departmentBody } = body;
    try {
      idlState.on();
      const res = await myAccountRepository.createDepartment(
        hospitalId,
        departmentBody
      );
      const toastProps = {
        header: 'Success!',
        description: `${'Department created'}`,
      };
      successToast(toast, toastProps);
      return res;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response)
    } finally {
      idlState.off();
    }
  };

  const getDepartmentByKeyword = async keyword => {
    try {
      const response = await myAccountRepository.getDepartmentByKeyword(
        keyword
      );
      const data = response.map(d => ({ ...d, label: d.name, value: d.id }));
      setDepartmentDropdown(data);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const updateHealthSystemPracticeInfo = async body => {
    try {
      const response = await myAccountRepository.updateHealthSystemPracticeInfo(
        body
      );
      const toastProps = {
        header: 'Success!',
        description: `Updated`,
      };
      setCurrentUserPracticeInfo(response)
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      const response = error.cause;
     showErrorMessage(response)
    }
  };

  const updateHospitalPracticeInfo = async body => {
    try {
      const response = await myAccountRepository.updateHospitalPracticeInfo(
        body
      );
      const toastProps = {
        header: 'Success!',
        description: `Updated`,
      };
      setCurrentUserPracticeInfo(response)
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      const response = error.cause;
      showErrorMessage(response);
    }
  };

  const updateDepartmentPracticeInfo = async body => {
    try {
      const response = await myAccountRepository.updateDepartmentPracticeInfo(
        body
      );
      const toastProps = {
        header: 'Success!',
        description: `Updated`,
      };
      setCurrentUserPracticeInfo(response)
      successToast(toast, toastProps);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
      const response = error.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        if (response.errors) {
          const values = Object.values(response.errors);
          toast.showError({
            description: values.map(value => {
              return (
                <span key={value}>
                  {value}
                  <br />
                </span>
              );
            }),
          });
        }
      }
    }
  };

  const deleteHospital = async id => {
    try {
      const response = await myAccountRepository.deleteHospital(id);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };
  const deleteDepartment = async id => {
    try {
      const response = await myAccountRepository.deleteDepartment(id);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const getDepartmentByHospital = async id => {
    try {
      const response = await myAccountRepository.getDepartmentByHospital(id);
      const data = response.map(d => ({ ...d, label: d.name, value: d.id }));
      setDepartmentDropdown(data);
      return data;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    }
  };

  const mContext = {
    currentUserType,
    isFetchingDepartment,
    userTypeLookup,
    editUserData,
    editUserTypeLogo,
    isGettingUserType,
    currentUserPracticeInfo,
    practiceInfoHealthSystem,
    practiceInfoProvider,
    praticeInfoHospital,
    praticeInfoPhysicianGroup,
    praticeInfoDepartment,
    fetchEHROptions,
    createEHROptions,
    fetchInsuranceOptions,
    fetchSpecialtyOptions,
    createInsuranceOption,
    createSpecialtyOption,
    ehrOptions,
    specialtyOptions,
    insuranceOptions,
    isFetchingEHR,
    isFetchingSpecialtyOption,
    isCreatingEHR,
    isCreatingSpecialtyOption,
    isFetchingInsurance,
    isCreatingInsurance,
    departmentDropdown,
    setDepartmentDropdown,

    createHospital,
    getProvider,
    getDepartmentDropdown,
    createDepartment,
    getDepartmentByKeyword,
    updateHealthSystemPracticeInfo,
    updateHospitalPracticeInfo,
    updateDepartmentPracticeInfo,
    deleteHospital,
    deleteDepartment,
    getDepartmentByHospital,
    isDepartmentLoading,
    isUpdatingUserData,
    setSpecialtyOptions,
    setCurrentUserType,
    uploadingLogoStatus
  };

  return (
    <MyAccountContext.Provider value={mContext}>
      {props.children}
    </MyAccountContext.Provider>
  );
};

export default MyAccountProvider;
