import { ClientType, getCurrentClientType } from "./clientTypeUtils";

const logoStatus = Object.freeze({
  NOT_EMPTY: "NOT_EMPTY",
  EMPTY: 'Empty',
  NOT_QUALIFIED: "NOT_QUALIFIED"
});

const getCurrentUserTypeId = currentUser => {
  switch (currentUser?.user_type) {
    case 'HEALTH SYSTEM':
      return {
        health_system_id: currentUser.health_system_id?.id,
      };
    case 'HOSPITAL':
      return {
        hospital_id: currentUser.hospital_id?.id,
      };
    case 'DEPARTMENT':
      return {
        department_id: currentUser.department_id?.id,
      };
    case 'PROVIDER':
      return {
        provider_id: currentUser.provider_id?.providers__id,
      };

    case 'PHYSICIANS GROUP':
      return {
        health_system_id: currentUser.physicians_group_id?.id,
      };
    default:
      return {};
  }
};
const getCurrentUserTypeName = currentUser => {
  switch (currentUser?.user_type) {
    case 'HEALTH SYSTEM':
      return currentUser.health_system_id?.name || '';

    case 'HOSPITAL':
      return currentUser.hospital_id?.name || '';

    case 'DEPARTMENT':
      return currentUser.department_id?.specialty__name || '';

    case 'PROVIDER':
      return currentUser.provider_id?.name || '';

    case 'PHYSICIANS GROUP':
      return currentUser.physicians_group_id?.name || '';
    default:
      return '';
  }
};

const getCurrentUserTypeLogo = currentUser => {
  if (!currentUser) {
    return null;
  }
  const userType = getCurrentClientType(currentUser.user_type ?? currentUser.type);
  switch (userType) {
    case ClientType.HEALTH_SYSTEM:
      return currentUser.health_system_id?.logo || '';

    case ClientType.HOSPITAL:
      return currentUser.hospital_id?.logo || '';
      
      // For future reference. As of now only health_system and hospital has logos
    // case ClientType.DEPARTMENT:
    //   return currentUser.department_id?.specialty__name || '';

    // case ClientType.PHYSICIAN:
    //   return currentUser.provider_id?.name || '';

    // case ClientType.PHYSICIANS_GROUP:
    //   return currentUser.physicians_group_id?.name || '';
    default:
      return null;
  }
};

const isCurrentUserLogoQualified = currentUser => {
  if (!currentUser) {
    return null;
  }
  const userType = getCurrentClientType(currentUser.user_type ?? currentUser.type);
  return userType == ClientType.HEALTH_SYSTEM || userType == ClientType.HOSPITAL;
};

// for returning status with logo value
const getCurrentUserTypeLogoDetails = currentUser => {
  const logo = getCurrentUserTypeLogo(currentUser);
  if (logo == '') {
    return [logoStatus.EMPTY, null];
  }
  if (!logo) {
    return [logoStatus.NOT_QUALIFIED, null];
  }

  return [logoStatus.NOT_EMPTY, logo];
}



export { getCurrentUserTypeId,
        getCurrentUserTypeName, 
        getCurrentUserTypeLogo, 
        getCurrentUserTypeLogoDetails,
        isCurrentUserLogoQualified, 
        logoStatus
      };
