import { Box, Button, HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text, Tooltip, PopoverHeader, list } from '@chakra-ui/react'
import React from 'react'
import AppColors from '../../constants/AppColors'
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData'
import { FONT_FAMILY } from '../../constants/Theme'
import { toTitleCase, replaceRoute } from '../../utils/common.utils'
import { getCurrentClientType } from '../../utils/clientTypeUtils'
import { getSubDashboardRoute } from '../../utils/dashboardTransitionUtils'
import DashboardProvider, {
  DashboardContext,
} from '../../providers/DashboardProvider';
import './thermometer.css'
import { MapInteractionCSS } from 'react-map-interaction';
import { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from '../../providers/AuthProvider';

const Thermometer = (props) => {

  const _generateOptions = () => {

    return {
      theme: () => props.theme === 'light' || props.theme === 'dark' ? props.theme : 'light',
      value: props.value || 0, //default 0
      max: props.max || 100, //default 100
      steps: props.steps,
      format: props.format || '',
      size: () => props.size === 'small' || props.size === 'normal' || props.size === 'large' ? props.size : 'normal',
      height: props.height || 200, //default 200
      valstr: () => ((options.value % 1) > 0 ? options.value.toFixed(1) : options.value) + options.format,
      percent: () => options.value / options.max * 100,
      reverseGradient: props.reverseGradient || false, // default false
      intervals: [],
      limit: props.limit ? props.limit : {},
      list: props.list ? props.list : [],
      tile: props.tile ? props.tile : false,
      width: props.width ? props.width : 0,
      diff: props.risk_temperature_diff ? props.risk_temperature_diff : 0
    }
  }

  const options = _generateOptions()

  const _createIntervals = () => {
    if (options.steps) {
      for (let step = 0; step <= options.steps; step++) {
        let val = ((options.max / options.steps) * step)
        let percent = (val / options.max) * 100
        let interval = { percent: percent, label: val + options.format }
        options.intervals.push(interval)
      }
    }
  }
  const _createIntervalsUI = (intervals) => {
    return intervals.map((step, i) => {
      return (
        <li key={`${i}-interval`} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
          {step.label}
        </li>
      )
    })
  }

  const _createGoalIntervalsUI = (intervals) => {
    return <ul className="thermometer__goal__statistics">
      {
        intervals.below ? <li key={`${intervals.below}-goal`} style={{ bottom: `calc(${(intervals.below / options.max) * 100}% - 1px)`, borderColor: AppColors.secondary }}>
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Bottom Parameter:</span> {intervals.below}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'
            }}>
            </Box>
          </Tooltip>
        </li> : null}
      {
        intervals.above ? <li key={`${intervals.above}-goal`} style={{ bottom: `calc(${(intervals.above / options.max) * 100}% - 1px)` }}>
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Top Parameter:</span> {intervals.above}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'

            }}>
            </Box>
          </Tooltip>
        </li> : null}
      {
        intervals.goal ? <li key={`${intervals.goal}-goal`} style={{ bottom: `calc(${(intervals.goal / options.max) * 100}% - 1px)` }}
        >
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Perfect Temperature:</span> {intervals.goal}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'

            }}>
            </Box>
          </Tooltip>
        </li> : null}
    </ul>
  }

 const PopOverWrapper = ({subViewsForRiskTemp}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dashboardContext = useContext(DashboardContext);
  const {filterParams} = dashboardContext;
  const { hospitalId } = useParams();
  const { departmentId } = useParams();
  const { physicianId } = useParams();
  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;

  const isPhysicianDashboard = physicianId ? true : false;

  const handleOpen = () => setIsOpen(true); 
  const handleClose = () => setIsOpen(false);

  
  const moveToSubView = (id, name) => {
    const [route, routeParamDict] = getSubDashboardRoute(getCurrentClientType(currentUser?.user_type),
                                                         hospitalId,
                                                         departmentId,
                                                         id);
    
    navigate(replaceRoute(
      route,
      routeParamDict
    ),
      { state: name }
    );
  }

  return (
    <Popover 
    isOpen={isOpen}
    onOpen={handleOpen}
    onClose={handleClose}
    placement='left'
    >

          
      <PopoverTrigger>
          <Box style={{
            zIndex: 1,
            width: '100%',
            position: "absolute",
            top: "initial",
            right: "initial",
            bottom: "initial",
            left: "0",
            borderBottom: '2px solid #000',
            cursor: 'pointer',
          }}
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          >
          </Box>
        </PopoverTrigger>
        <PopoverContent
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        borderWidth={2}
        borderColor='gray.300'
        borderRadius="md"
        backgroundColor="gray.50"
        color="black"
        boxShadow="md"
        width="auto"
        maxWidth="400px"
        height="auto"
        maxHeight="400px" 
        overflow="auto" 
        >
          <PopoverArrow />
          <PopoverHeader>
            <Box
              p={2}
              borderWidth={1}
              borderColor='gray.200'
              borderRadius="sm"
              backgroundColor="gray.75"
              color={AppColors.primary}
              // fontWeight="bold"
              fontSize={16}
              mb={0}
              
            >
              Risk Temperature: {subViewsForRiskTemp[0].risk_temperature}°F <br/>
              

            </Box>
          </PopoverHeader>
          <PopoverBody>
          
            <ul 
              style={{
                marginLeft: '-30px'
              }}
              >
          
      
                {subViewsForRiskTemp.map((d, index) => {
                  return (
                    <div>
                    <li
                    key={`${d.name}`} 
                    style={{
                      marginLeft: 1,
                    }}>
                      <Button
                      size={'sm'}
                      bg={AppColors.secondary}
                      color={'white'}
                      borderRadius="3xl"
                      px={5}
                      py={5}
                      style={{
                        minWidth: '180px'
                      }}
                      fontWeight="normal"
                      fontSize={'sm'}
                      _hover={{
                        transition: 'all .1s ease',
                        bgColor: AppColors.white,
                        color: AppColors.secondary,
                        outline: `2px solid ${AppColors.secondary}`,
                      }}
                      onClick={() => moveToSubView(d.id, d.name)}
                      disabled={isPhysicianDashboard}
                      _disabled={{
                        bgColor: AppColors.white,
                        color: AppColors.secondary,
                        outline: `2px solid ${AppColors.secondary}`,
                        pointerEvents: "none",
                      }}
                      >
                        {toTitleCase(d.name)} </Button> </li> <br/><br/><br/>
                        </div>
                      )
                      })}
  
            </ul>
  
          </PopoverBody>
        </PopoverContent>
      
    </Popover>
  )}
 
  const _createListIntervalsUI = (intervals) => {
    return <ul className="thermometer__list__statistics">
      {Object.keys(intervals).map((riskTemp, index) => (
        <li 
        key={`${riskTemp}-${index}-temp`}
        style={{ 
          bottom: `calc(${(riskTemp / options.max) * 100}% - 1px)` 
        }}
        >
        <PopOverWrapper 
        subViewsForRiskTemp={intervals[riskTemp]}
        />
      </li>
      ))}
    </ul>
  }

  const _createTemperatureToSubViewDict = (subViewsData) => {
    let temperatureToSubView = {};
    for (let subView of subViewsData) {
      if (temperatureToSubView[subView.risk_temperature] == undefined) {
        temperatureToSubView[subView.risk_temperature] = []
      }
      temperatureToSubView[subView.risk_temperature].push(subView)
    }
  
    return temperatureToSubView;
  }


  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }
  })
  useEffect(() => {
    if (options.width) {
      setValue(prev => ({
        ...prev,
        translation: { x: (options.width / 2.7), y: 0 }
      }))
    }
  }, [options.width])
  const theme = `thermometer--theme-${options.theme()}`
  const size = `thermometer--${options.size()}`
  const height = { height: `${options.height}px` }
  const heightPercent = { height: `${options.percent()}%` }
  const heightBgColor = { height: `calc(${options.height}px - 57px)` }
  const reverse = options.reverseGradient ? 'Reverse' : ''
  _createIntervals()
  const intervals = options.limit
  const stepIntervals = _createIntervalsUI(options.intervals)
  const stepGoalIntervals = _createGoalIntervalsUI(options.limit)

  const diff = options.diff
  const valstr = options.valstr()
  const temperaturesToSubViewDict = _createTemperatureToSubViewDict(options.list)
  const stepListIntervals = _createListIntervalsUI(temperaturesToSubViewDict)

  const renderThermometer = () => {
    return <div style={{ ...height, position: 'relative', marginTop: 10, marginBottom: 10 }} className={`thermometer ${size} ${theme}`}>
      <div style={{ position: 'absolute', bottom: 10, left: 25, zIndex: 10, textAlign: 'center', width: 50 }}>
        <Text lineHeight={1} textAlign='center'
          color='white' fontSize={props.value > 100 ? 'xs' : 'md'}>{valstr}</Text>
        <HStack justifyContent={'center'}>
          {diff >= 0 ? (
            <TriangleIcon style={{ height: 10, width: 10 }} />
          ) : (
            <TriangleRedIcon style={{ height: 10, width: 10 }} />
          )}
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={'xs'}
            color={'white'}
            lineHeight={1}
            style={{ marginLeft: 1 }}
          >
            {diff >= 0 ? '+' : null}
            {diff}
          </Text>
        </HStack>
      </div>
      <div className="thermometer__draw-a"></div>
      <div className={`thermometer__draw-b${reverse}`}></div>
      <div className="thermometer__meter">
        <ul className="thermometer__statistics">{stepIntervals}</ul>
        {stepGoalIntervals}
        {stepListIntervals}
        <div style={heightPercent} className="thermometer__mercury">

          <div className="thermometer__mask">
            <div className={`thermometer__bg-color${reverse}`}
              style={{
                ...heightBgColor,
                background: `linear-gradient(#fa7e57 ,#F9480F ${100 - ((intervals.above / options.max) * 100)}% , #43DAD4 ${100 - ((intervals.goal / options.max) * 100)}%, #023B93 ${100 - ((intervals.below / options.max) * 100)}%, #4d75b3)`
              }}
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  return options.tile ? <MapInteractionCSS
    value={value}
    minScale={1}
    onChange={(data) => {
      setValue(data)
    }}
    // disablePan={true}
    translationBounds={{
      xMin: -10, xMax: (options.width - 100)
    }}
  >
    {renderThermometer()}
  </MapInteractionCSS> : renderThermometer()

}

export default Thermometer;