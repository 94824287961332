const ClientType = Object.freeze({
    HEALTH_SYSTEM: 'HEALTH_SYSTEM',
    HOSPITAL: 'HOSPITAL',
    DEPARTMENT: 'DEPARTMENT',
    PHYSICIAN: 'PHYSICIAN', 
    PHYSICIANS_GROUP: 'PHYSICIANS_GROUP',
    UNDEFINED: "USER_NOT_DEFINED" 
})

const userTypeToEnumMapping = Object.freeze({
    'HEALTH SYSTEM': 'HEALTH_SYSTEM',
    'HOSPITAL': 'HOSPITAL',
    'DEPARTMENT': 'DEPARTMENT',
    'PROVIDER': 'PHYSICIAN',
    'PHYSICIANS GROUP': 'PHYSICIANS_GROUP'
})

const userTypeToDictionaryKeyMapping = Object.freeze({
    'HEALTH SYSTEM': 'health_system_id',
    'HOSPITAL': 'hospital_id',
    'DEPARTMENT': 'department_id',
    'PROVIDER': 'provider_id',
    'PHYSICIANS GROUP': 'physicians_group_id'
})

const getCurrentClientType = user_type => {
    return userTypeToEnumMapping[user_type] ?
     userTypeToEnumMapping[user_type]: ClientType.UNDEFINED;
};

const getDictionaryKeyForClientTypeData = user_type => {
    return userTypeToDictionaryKeyMapping[user_type] ?
    userTypeToDictionaryKeyMapping[user_type] : ClientType.UNDEFINED; 
}

export { getCurrentClientType, ClientType, getDictionaryKeyForClientTypeData }