/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormLabel,
  GridItem,
  SimpleGrid,
  Spinner,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import MyAccountProvider, {
  MyAccountContext,
} from '../../../providers/MyAccountProvider';
import { CommonMyAccountComponents } from './CommonMyAccountComponents';
import DepartmentMyAccount from './DepartmentMyAccount';
import ErrorComponent from './ErrorComponent';
import HealthSystemMyAccount from './HealthSystemMyAccount';
import HospitalMyAccount from './HospitalMyAccount';
import PhysicianGroupMyAccount from './PhysicianGroupMyAccount';
import ProviderMyAccount from './ProviderMyAccount';
import { getCurrentClientType, ClientType } from '../../../utils/clientTypeUtils';

const MyAccountsWrapper = () => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUser = myAccountContext.currentUserType;

  useEffect(() => {
    myAccountContext.userTypeLookup();
  }, []);


  const getData = async(formData, isAccountContactChanged) => {
    const userNames = {
      first_name: myAccountContext.currentUserType.first_name,
      last_name: myAccountContext.currentUserType.last_name,
    };
    let updatedBody = {...formData , my_account_update_flag: true};

    if (isAccountContactChanged) {
      updatedBody = {
        ...userNames,
        ...updatedBody,
        account_contact: formData.account_contact,
      };
    } else {
      updatedBody = {
        ...userNames,
        ...updatedBody,
        account_contact: currentUser.account_contact,
      };
    }
  await myAccountContext.editUserData(updatedBody);
  };
  
  const renderComponentByUser = () => {
    const userType = getCurrentClientType(currentUser.type);
    switch (userType) {
      case ClientType.HEALTH_SYSTEM:
        return <HealthSystemMyAccount getData={getData} />;
      case ClientType.HOSPITAL:
        return <HospitalMyAccount getData={getData} />;
      case ClientType.DEPARTMENT:
        return <DepartmentMyAccount getData={getData} />;
      case ClientType.PHYSICIANS_GROUP:
        return <PhysicianGroupMyAccount getData={getData} />;
      case ClientType.PHYSICIAN:
        return <ProviderMyAccount getData={getData} />;

      default:
        return <ErrorComponent />;
    }
  };

  return (
    <>
      {myAccountContext.currentUserType !== null ? (
        <SimpleGrid
          width={'70%'}
          m={'auto'}
          my={4}
          columns={{ sm: 1, md: 1, lg: 1 }}
          spacing={10}
          fontSize={'xl'}
        >
          <GridItem mb={'15%'}>
            <FormLabel
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={'bold'}
              fontSize={'2xl'}
              color={AppColors.black}
            >
              My Account
            </FormLabel>
            <CommonMyAccountComponents
              getData={getData}
            />
            {renderComponentByUser()}
          </GridItem>
          <GridItem>
            {/* <FormLabel
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={'bold'}
              fontSize={'2xl'}
              color={AppColors.black}
            >
              Payments
            </FormLabel> */}
          </GridItem>
        </SimpleGrid>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default withProvider(MyAccountProvider, MyAccountsWrapper);
