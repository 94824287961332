import BlankLayout from '../components/layout/BlankLayout';
import ROUTES from '../constants/Routes';
import AuditSheetContainer from '../pages/audit_sheet/AuditSheetContainer';
import BatchContainer from '../pages/dashboard/batch_dashboard/BatchContainer';
import ChartContainer from '../pages/dashboard/ChartContainer';
import DashboardContainer from '../pages/dashboard/DashboardContainer';
import DepartmentDBContainer from '../pages/dashboard/department_dashboard/DepartmentDBContainer';
import HospitalDashboardContainer from '../pages/dashboard/hospital_dasboard/HospitalDashboardContainer';
import PhysicianDashboardcotainer from '../pages/dashboard/physician_dashboard/PhysicianDashboardcotainer';
import MyChartsContainer from '../pages/my_charts/MyChartsContainer';
import ChartActivityContainer from '../pages/dashboard/ChartActivityContainer';

const ManagerRouter = [
  {
    route: ROUTES.DASHBOARD,
    element: <DashboardContainer />,
  },
  {
    route: ROUTES.MY_CHARTS,
    element: <MyChartsContainer />,
  },
  {
    route: ROUTES.VIEW_AUDIT_SHEET,
    element: <AuditSheetContainer view={true} batch />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.SINGLE_AUDIT_SHEET,
    element: <AuditSheetContainer view={true} />,
    layout: BlankLayout,
  },
  {
    route: ROUTES.HOSPITAL_DASHBOARD,
    element: <HospitalDashboardContainer />,
  },
  {
    route: ROUTES.CHARTS,
    element: <ChartContainer />,
  },
  {
    route: ROUTES.CHART_ACTIVITY_BREAKDOWN,
    element: <ChartActivityContainer />,
  },
  {
    route: ROUTES.DEPARTMENT_DASHBOARD,
    element: <DepartmentDBContainer />,
  },
  {
    route: ROUTES.PHYSICIAN_DASHBOARD,
    element: <PhysicianDashboardcotainer />,
  },

  {
    route: ROUTES.BATCH_DASHBOARD,
    element: <BatchContainer />,
  },
  {
    route: ROUTES.HOSPITAL_DEPARTMENT_DASHBOARD,
    element: <DepartmentDBContainer />,
  },
  {
    route: ROUTES.HOSPITAL_PHYSICIAN_DASHBOARD,
    element: <PhysicianDashboardcotainer />,
  },
  {
    route: ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
    element: <PhysicianDashboardcotainer />,
  },
];

export default ManagerRouter;
