import {
    Box,
    Circle,
    HStack,
    IconButton,
    Text,
    Tab,
    Tabs,
    TabList,
    TabPanels,
    TabPanel
  } from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import AppColors from '../../../constants/AppColors';
import { EditIcon,
    UploadIcon,
    GoogleDriveIcon,
    MsOneDriveIcon,
    DefaultClientLogoIcon,
    SubmitClearIcon } from '../../../constants/IconData';
import { CloseIcon } from '@chakra-ui/icons';


const LogoComponent= ({
    attribute, 
    logo, 
    logoWidthpercent,
    handleLogoUpdate }) => {
    
    const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png'];
    const MAX_IMAGE_SIZE_MB = 1
    const MAX_IMAGE_SIZE_BYTES = MAX_IMAGE_SIZE_MB * 1024 * 1024;
    const IMAGE_ERROR_CODE = 404;
    const IMAGE_STYLE = { width: "100%",
        height: 'auto',  
        padding: '5px'};
    
    const [selectedImage, setSelectedImage] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const fileInputRef = useRef(null);

    const [canEdit, setCanEdit] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittingText, setSubmittingText] = useState('');

    const previewURL = React.useMemo(() => {
        if (!selectedImage) {
            return null;
        }
        return URL.createObjectURL(selectedImage); // Create a preview URL
    }, [selectedImage]);

    useEffect(() => {
        return () => {
            if (previewURL) {
                URL.revokeObjectURL(previewURL); // Revoke the previous URL
            }
        };
    }, [previewURL]);
  
    const validateImageType = (type) => {
        return VALID_IMAGE_TYPES.includes(type);
    };
  
    const validateImageSize = (size) => {
        return size <= MAX_IMAGE_SIZE_BYTES;
    };
  
    const validateImage = (file) => {
        let error_msg = ""
        if (!validateImageType(file.type)) {
            error_msg += "Please upload a valid image file (JPEG, PNG).";
        }
    
        if (!validateImageSize(file.size)) {
            if (error_msg) {
            error_msg += "\n";
            }
            error_msg += ("Please upload the image under " + String(MAX_IMAGE_SIZE_MB) + " MB");
        }
        if (error_msg) {
            alert(error_msg);
            return IMAGE_ERROR_CODE;
        }
        return 0;
    };

    const refreshFileRefInput = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };
  
    const handleImageChange = (event) => {
        const file = event.target.files[0]; // Get the first selected file
        if (validateImage(file) === IMAGE_ERROR_CODE) {
            refreshFileRefInput();
            return ;
        }
        
        if (file) {
            setSelectedImage(file); // Set the selected image for preview
            setIsUploaded(true); // Set uploaded state to true
        }
    };
  
    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger the file input
    };
  
    
    const handleImageSubmit = async (key) => {
        // Handle the submission of the image (e.g., send it to a server)        
        let dotCount = 0;
        let text = previewURL ? 'Submitting' : 'Deleting';
        const dotInterval = setInterval(() => {
            dotCount = (dotCount + 1) % 6; 
            setSubmittingText(text + '.'.repeat(dotCount)); // Add dots
        }, 500);

        setIsSubmitting(true);
        await handleLogoUpdate(key, selectedImage);
        setIsSubmitting(false);

        clearInterval(dotInterval);
        setSubmittingText('');
        // Reset the state if needed
        
        closeUpdatePanels();
         
    };


    const handleDeleteImage = (key) => {
        resetImageData();
        handleImageSubmit(key);
    }


    const resetImageData = () => {
        setSelectedImage(null);
        setIsUploaded(false);
        refreshFileRefInput();
         
    };

    

    const toggleEdit = () => {
        setCanEdit(prev => !prev);
    }

    const closeUpdatePanels = () => {
        resetImageData();
        toggleEdit();
    }

    const previewBlock = () => (
        <div>
            <span
                    style={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      color: AppColors.secondary,
                      animation: 'fadeIn 1s ease-in-out',
                    }}
                  > Preview
            </span>
                <div style={IMAGE_STYLE}>
                    <img src={previewURL} alt="Uploaded"/>
                </div>
        </div>
    );

    
  // ------------------------------------Image Change end-------------------------------------------------------
  
    const renderImageData = (key) => {
        return (
        
        <Box 
            bgColor={AppColors.white}
            boxShadow={'md'}
            borderRadius={'lg'} 
            style={{ 
                width: logoWidthpercent, 
                height: 'auto', 
                paddingBottom: '3px'
                }}>
            <div style={IMAGE_STYLE}>
                {logo ? 
                (<img src={logo} alt="Client Type Logo" ></img>)
                :
                <DefaultClientLogoIcon/>
                }
            </div>
   
            {canEdit && (!isSubmitting ? (
            <div
            style={{ marginTop: '2px' }}
            >
                <Box>
                    <SubmitClearIcon 
                        handleSubmit={handleUploadClick}
                        text={<span style={{ display: 'inline-flex', alignItems: 'center' }}>
                            Upload Logo
                            </span>}
                        width={'100%'}
                        height={'30px'}
                        />
                    
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: 'none' }} // Hide the file input
                        ref={fileInputRef}
                    />

                </Box>
            {/* Please comment out following code in feature if we need google and one drive support for logo upload */}
            {/* <Tabs variant="enclosed-colored" isFitted marginBottom={0.5}>
                <TabList marginBottom={0.5}>
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }} >
                        <UploadIcon style={{ marginRight: 3, width: 25, height: 25 }} /> 
                    </Tab>
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }}>
                        <GoogleDriveIcon style={{ marginRight: 3, width: 25, height: 25 }} />
                    </Tab>
                    <Tab _selected={{ bg: '#48BB78', color: 'white' }}>
                        <MsOneDriveIcon style={{ marginRight: 3, width: 25, height: 25 }} />
                    </Tab>
                </TabList>


                    <TabPanels >
                        <TabPanel style={{padding: 0}}>
                        <Box>
                            <SubmitClearIcon 
                                handleSubmit={handleUploadClick}
                                text={'Local Upload'}
                                width={'100%'}
                                height={'30px'}
                                />
                            
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: 'none' }} // Hide the file input
                                ref={fileInputRef}
                            />
       
                        </Box>
                        </TabPanel>
                        <TabPanel style={{padding: 0}}>
                            <Box>
                                <SubmitClearIcon 
                                handleSubmit={handleUploadClick}
                                    text={'G-Drive Upload'}
                                    width={'100%'}
                                    height={'30px'}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel style={{padding: 0}}>
                            <Box>
                                <SubmitClearIcon 
                                    handleSubmit={handleUploadClick}
                                    text={'One-Drive Upload'}
                                    width={'100%'}
                                    height={'30px'}                                    
                                />
                        </Box>
                        </TabPanel>
                    </TabPanels>
            </Tabs> */}

            

            {previewURL ? (
                <div>
                    <SubmitClearIcon 
                        handleSubmit={resetImageData}
                        bgColour={'red'}
                        text={'Clear upload Logo'}
                        width={'100%'}
                        height={'30px'}
                    />
                    {previewBlock()}
                </div>
                ) : (
                    logo && (
                        <SubmitClearIcon 
                            handleSubmit={() => handleDeleteImage(key)}
                            bgColour={'red'}
                            text={'Delete Logo'}
                            width={'100%'}
                            height={'28px'}
                        />
                    )
                )
            }
            
                

            {/* <Progress value={myAccountContext.uploadingLogoStatus.value} size="xs" colorScheme={'teal'} borderRadius={10} /> */}
            {isUploaded && (
                <SubmitClearIcon
                    handleSubmit={() => handleImageSubmit(key)}
                    text={'Submit'}
                    width={'100%'}
                    height={'28px'}
                />
                                
                )
            }
            </div>) :
            (
                <div>
                  {previewURL && (
                    previewBlock()
                  )}
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: previewURL ? '#48BB78' : 'red',
                      animation: 'fadeIn 1s ease-in-out',
                    }}
                  >
                    {submittingText}
                  </span>
                </div>
              )
            )}

        </Box>)
    }


    return (
        <HStack justifyContent={'space-between'} mt={5}>
        <Box>
            <Text color={AppColors.secondary} fontSize={'md'} fontWeight="bold">
              Hospital Logo
            </Text>
            {renderImageData(attribute)}
          </Box>
          
          {!canEdit ? (
            <Circle
              size="40px"
              bg={'#EBEBEE'}
              onClick={() => toggleEdit()}
              cursor={'pointer'}
            >
              <EditIcon />
            </Circle>
          ) : 
          <IconButton
          borderRadius={'full'}
          p={1}
          bg={'white'}
          borderWidth={1}
          borderColor={AppColors.gray}
          icon={<CloseIcon boxSize={3} color={'black'} />}
          onClick={closeUpdatePanels}
        />}
        </HStack>
        );

};

export default LogoComponent;